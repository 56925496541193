import { Hotel } from "typings/microserviceModels/hotels-flex-module";
import * as React from "react";
import { LocalizedText } from "bernie-l10n";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { UitkText } from "@egds/react-core/text";

/**
 * Checks hotel model for freeCancellation or payLater properties
 * and returns an element with the appropriate text
 */
export const ValueProposition = React.memo(({ hotel }: { hotel: Hotel }) => {
  return (
    <div className="ValueProposition">
      <UitkLayoutFlex direction="column">
        {hotel.freeCancellation && (
          <UitkLayoutFlexItem>
            <UitkText inline size={300}>
              <LocalizedText message="hotels.fullyRefundable" />
            </UitkText>
          </UitkLayoutFlexItem>
        )}
        {hotel.payLater && (
          <UitkLayoutFlexItem>
            <UitkText inline size={300}>
              <LocalizedText message="hotels.reserveNowPayLater" />
            </UitkText>
          </UitkLayoutFlexItem>
        )}
      </UitkLayoutFlex>
    </div>
  );
});
