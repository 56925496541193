import { inject } from "mobx-react";
import { Hotel } from "typings/microserviceModels/hotels-flex-module";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { UitkFigure, UitkFigureAspectRatioType, UitkImage } from "@egds/react-core/images";
import * as React from "react";
import { AnalyticsStore } from "bernie-plugin-mobx";
import { HotelBadge } from "./HotelBadge/HotelBadge";
import { getOptimizedImageUrl } from "src/components/flexComponents/Hotels/utils/hotelOptimizedImage";

interface CardMediaOverallInfoProps {
  index: number;
  hotel: Hotel;
  analytics?: AnalyticsStore;
  context: ExtendedContextStore;
  moduleName?: string;
  displayBadges?: boolean;
}

/**
 * Represents a {@see HotelCard}'s media (image, carousel, favorite button, etc.)
 */

export const CardMediaOverallInfo: React.FC<CardMediaOverallInfoProps> = inject("analytics")(
  ({ hotel, context, moduleName = "hotels", displayBadges, ...props }) => {
    const { hotelName } = hotel;
    const hotelImageUrl = getOptimizedImageUrl(hotel, context);

    return (
      <UitkFigure ratio={UitkFigureAspectRatioType.R21_9} {...props}>
        <div>
          <UitkImage src={hotelImageUrl} alt={hotelName} placeholderImage lazyLoading="lazy" />
        </div>
        {displayBadges && <HotelBadge travelerReviewContent={hotel.travelerReviewContent} />}
      </UitkFigure>
    );
  }
);
