// External Libraries
import * as React from "react";

// UITK
import { UitkLink } from "@egds/react-core/link";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkText } from "@egds/react-core/text";
// Types
import { FilterPill } from "src/components/flexComponents/Hotels/typings";

// Utils
import { getHSRStarRatingCode } from "components/utility/ContextUtil";

// Components
import { HotelAffinityFilters } from "components/flexComponents/Hotels/components/HotelAffinityFilters/HotelAffinityFilters";
import { TrackedLink } from "src/components/utility/analytics/TrackedLink";
import { useLocalization } from "@shared-ui/localization-context";
import { useHotelsContext } from "components/flexComponents/Hotels/components/HotelsContext";

/**
 * @description
 * HotelStarRatingAffinityFilters: Specific Component Filters to Star Rating Affinity Pages
 */
export const HotelStarRatingAffinityFilters: React.FC<{}> = () => {
  const { templateComponent, model, context } = useHotelsContext();
  const moduleName = templateComponent.type as string;
  const { formatText } = useLocalization();

  const pageStarRating = getHSRStarRatingCode(context);

  if (!pageStarRating || !model.showSuggestedFilters) {
    return null;
  }

  const { searchContext } = context;

  const regionName: string = searchContext?.location?.name;

  const filterPills: FilterPill[] = [
    {
      label: formatText("themeSelector.affinity.pool"),
      queryFilter: `&star=${pageStarRating}&amenities=POOL`,
      key: "affinity-filter-pool",
    },
    {
      label: formatText("themeSelector.affinity.spa"),
      queryFilter: `&star=${pageStarRating}&amenities=SPA_ON_SITE`,
      key: "affinity-filter-spa",
    },
    {
      label: formatText("themeSelector.affinity.hottub"),
      queryFilter: `&star=${pageStarRating}&amenities=HOT_TUB`,
      key: "affinity-filter-hottub",
    },
    {
      label: formatText("amenities.description.breakfastIncluded"),
      queryFilter: `&star=${pageStarRating}&amenities=FREE_BREAKFAST`,
      key: "affinity-filter-breakfast",
    },
    {
      label: formatText("themeSelector.affinity.waterpark"),
      queryFilter: `&star=${pageStarRating}&amenities=WATER_PARK`,
      key: "affinity-filter-waterpark",
    },
  ];

  return (
    <UitkSpacing margin={{ block: "four" }}>
      <section>
        <UitkText size={300} weight="medium" theme="emphasis" align="center">
          {formatText("hotels.affinity.starRating.looking.for")}
        </UitkText>
        <UitkSpacing margin={{ blockend: "three" }}>
          <UitkText align="center">
            <UitkLink inline>
              <TrackedLink
                moduleName={moduleName}
                href={model.seeAllLink}
                rfrr={model.seeAllType}
                linkName={model.seeAllLink}
                rel="nofollow"
              >
                {formatText("hotels.affinity.starRating.see.all", regionName)}
              </TrackedLink>
            </UitkLink>
            <UitkText size={300}>{formatText("hotels.affinity.starRating.find.more")}</UitkText>
          </UitkText>
        </UitkSpacing>
        <HotelAffinityFilters filters={filterPills} />
      </section>
    </UitkSpacing>
  );
};
