import { Hotel, HotelReview } from "typings/microserviceModels/hotels-flex-module";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { UitkCardContentSection } from "@egds/react-core/cards";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkText, UitkParagraph } from "@egds/react-core/text";
import * as React from "react";
import { LocalizedComponentClass, LocalizedText, withLocalization } from "bernie-l10n";
import { ReviewCountAndRating } from "./ReviewCountAndRating";
import { TravelerReviewsContent } from "components/flexComponents/Hotels/components/TravelerReviewsContent";
import { useLocalization } from "@shared-ui/localization-context";
import { UitkLayoutGrid, UitkLayoutGridItem } from "@egds/react-core/layout-grid";
import { PriceLockupGetRates } from "src/components/flexComponents/Hotels/views/HotelsViews/components/shared/PriceLockup/PriceLockupGetRates";

interface FeaturedReviewContentProps {
  hotel: Hotel;
  context: ExtendedContextStore;
  hotelLink: string;
  showGuestRating?: boolean;
  hasGetRatesText?: boolean;
}

function showReview(featuredReviews?: HotelReview[]) {
  return featuredReviews && featuredReviews.length > 0;
}

export function showReviewCountAndRating(
  showGuestRating?: boolean,
  reviewCount?: number,
  hotelOverallReviewRating?: number,
  featuredReviews?: HotelReview[]
) {
  return (
    (showGuestRating || (showGuestRating == null && showReview(featuredReviews))) &&
    (reviewCount || reviewCount === 0 || hotelOverallReviewRating || hotelOverallReviewRating === 0)
  );
}

export const FeaturedReviewComponent = (props: FeaturedReviewContentProps) => {
  const { featuredReviews, reviewCount, hotelOverallReviewRating, travelerReviewContent } = props.hotel;

  const hasReviews = featuredReviews && showReview(featuredReviews);
  const reviewCountAndRating = showReviewCountAndRating(
    props.showGuestRating,
    reviewCount,
    hotelOverallReviewRating,
    featuredReviews
  );

  if (!hasReviews && !props.hasGetRatesText && !reviewCountAndRating) {
    return null;
  }

  const hasReviewTitle = featuredReviews && featuredReviews[0].title;
  const showReviewDefaultTitle = !hasReviewTitle && travelerReviewContent;
  const hasBorder = reviewCountAndRating || hasReviews;
  const hasFeaturedReviewMargin = hasReviewTitle || reviewCountAndRating;

  const { formatText } = useLocalization();
  const language = props.context.locale?.split(/[_-]/);

  return (
    <UitkCardContentSection className="hotelCardContent" border={hasBorder ? "top" : undefined}>
      <UitkLayoutGrid columns={8}>
        <UitkLayoutGridItem colSpan={props.hasGetRatesText ? 6 : 8}>
          <div>
            {reviewCountAndRating && (
              <ReviewCountAndRating
                hotelOverallReviewRating={props.hotel.hotelOverallReviewRating}
                reviewCount={props.hotel.reviewCount}
                language={language}
                scoreDenominator={props.hotel.scoreDenominator}
                brand={props.context.site?.brand}
              />
            )}
            <TravelerReviewsContent travelerReviewContent={travelerReviewContent} hotelId={props.hotel.hotelId} />
            {hasReviews && showReviewDefaultTitle && (
              <UitkSpacing padding={{ blockstart: "three" }} margin={{ inlineend: "one" }}>
                <UitkText size={300} weight="bold" theme="emphasis">
                  {formatText("hotels.travelers.say")}
                </UitkText>
              </UitkSpacing>
            )}

            {hasReviews && hasReviewTitle && (
              <UitkText size={300} weight="bold" theme="emphasis">
                {featuredReviews[0].title}
              </UitkText>
            )}
            {hasReviews && featuredReviews[0].review && (
              <UitkSpacing margin={hasFeaturedReviewMargin ? { blockstart: "two" } : {}}>
                <UitkText overflow="truncate-3-lines" size={300}>
                  {`"${featuredReviews[0].review}"`}
                </UitkText>
              </UitkSpacing>
            )}
            {hasReviews && featuredReviews[0].reviewDate && (
              <UitkParagraph size={3}>
                <LocalizedText message="hotels.review.reviewedOn" data={[featuredReviews[0].reviewDate]} />
              </UitkParagraph>
            )}
          </div>
        </UitkLayoutGridItem>
        {props.hasGetRatesText && (
          <UitkLayoutGridItem colSpan={2} alignSelf="end" justifySelf="end">
            <div>
              <PriceLockupGetRates hotelLink={props.hotelLink} />
            </div>
          </UitkLayoutGridItem>
        )}
      </UitkLayoutGrid>
    </UitkCardContentSection>
  );
};

export const FeaturedReviewContent: LocalizedComponentClass<FeaturedReviewContentProps> = withLocalization(
  FeaturedReviewComponent
);
