import * as React from "react";

import { UitkCard, UitkCardContentSection, UitkCardLink } from "@egds/react-core/cards";
import { UitkFigure, UitkFigureAspectRatioType, UitkImage } from "@egds/react-core/images";
import { UitkHeading, UitkText } from "@egds/react-core/text";
import { UitkRating } from "@egds/react-core/rating";
import { UitkLayoutFlex } from "@egds/react-core/layout-flex";
import { UitkLayoutGrid } from "@egds/react-core/layout-grid";
import { useLocalization } from "@shared-ui/localization-context";
import { UitkSpacing } from "@egds/react-core/spacing";

import PriceLockup from "components/flexComponents/Hotels/views/HotelsViews/components/shared/PriceLockup";
import { PriceLockupAlign } from "components/shared/PriceLockup/typings";
import ValueProposition from "components/flexComponents/Hotels/views/HotelsViews/components/shared/ValueProposition";
import DistanceOrRating from "components/flexComponents/Hotels/views/HotelsViews/components/shared/DistanceOrRating";
import DescriptionOrReview from "components/flexComponents/Hotels/views/HotelsViews/components/shared/DescriptionOrReview";
import AmenitiesOrRecommendation from "components/flexComponents/Hotels/views/HotelsViews/components/shared/AmenitiesOrRecommendation";
import { getHotelLink, getHotelLinkRel } from "components/flexComponents/Hotels/components/HotelCard";

import { ratingIcon } from "components/utility/RatingUtil";
import { TrackedLink } from "components/utility/analytics/TrackedLink";

import { getOptimizedImageUrl } from "src/components/flexComponents/Hotels/utils/hotelOptimizedImage";
import { HotelPriceLockup } from "src/components/flexComponents/Hotels/utils/A11y/HotelPriceLockup";
import { fireAffinityProductCardSelectedClickstreamEvent } from "src/components/flexComponents/Hotels/utils/fireAffinityProductCardSelectedClickstreamEvent";
import { HotelImageCardProps } from "src/components/flexComponents/Hotels/typings";

const HotelCard = (props: HotelImageCardProps) => {
  const { hotel, model, context, index } = props;
  const { callToAction, destinationId } = model;
  const { hotelName, hotelStarRating, hotelFormattedFullAddress, payLater, freeCancellation } = hotel;

  const hotelImageUrl = getOptimizedImageUrl(hotel, context);

  const showValueProposition = payLater || freeCancellation;
  const { formatText } = useLocalization();

  return (
    <UitkCard onClick={fireAffinityProductCardSelectedClickstreamEvent(hotel, index)}>
      <UitkCardContentSection padded={false}>
        <UitkLayoutGrid columns={2}>
          <UitkFigure ratio={UitkFigureAspectRatioType.R1_1}>
            <UitkImage src={hotelImageUrl} alt={hotel.hotelName} placeholderImage lazyLoading="lazy" />
          </UitkFigure>
          <UitkSpacing padding="two">
            <UitkLayoutFlex direction="column">
              <UitkHeading tag="h3" size={5} overflow="truncate-2-lines">
                {hotelName}
              </UitkHeading>
              <UitkRating
                rating={hotelStarRating}
                icon={ratingIcon(context.locale, context.site.id)}
                className={context.site.brand}
              />
              <UitkText size={300} overflow="truncate-2-lines">
                {hotelFormattedFullAddress}
              </UitkText>
              <UitkSpacing margin={{ blockstart: "three" }}>
                <PriceLockup
                  hotel={hotel}
                  hotelLink={getHotelLink(hotel, model.destinationId, context)}
                  align={PriceLockupAlign.LEFT}
                  priceA11y={new HotelPriceLockup(hotel, formatText)}
                  hasGetRatesText
                />
              </UitkSpacing>
            </UitkLayoutFlex>
          </UitkSpacing>
        </UitkLayoutGrid>
      </UitkCardContentSection>

      <UitkCardContentSection border="top">
        <UitkLayoutFlex direction="column">
          <UitkSpacing margin={{ blockend: "three" }}>
            <div>
              <DistanceOrRating model={model} hotel={hotel} context={context} />
            </div>
          </UitkSpacing>
          <DescriptionOrReview model={model} hotel={hotel} />
          <UitkSpacing margin={{ blockstart: "three" }}>
            <AmenitiesOrRecommendation model={model} hotel={hotel} />
          </UitkSpacing>
        </UitkLayoutFlex>
      </UitkCardContentSection>

      {showValueProposition && (
        <UitkCardContentSection border="top">
          <ValueProposition hotel={hotel} />
        </UitkCardContentSection>
      )}

      <UitkCardLink>
        <TrackedLink
          data-testid={`hotelcard-link-${hotel.hotelName}`}
          aria-label={hotelName}
          moduleName="hotels"
          rfrr="card"
          rel={getHotelLinkRel(callToAction)}
          href={getHotelLink(hotel, destinationId || context.searchContext.location?.id, context, callToAction)}
        />
      </UitkCardLink>
    </UitkCard>
  );
};

export default HotelCard;
