import { HotelsFlexModuleResult } from "typings/microserviceModels/hotels-flex-module";
import { UitkLink } from "@egds/react-core/link";
import { TrackedLink } from "components/utility/analytics/TrackedLink";
import * as React from "react";
import { LocalizedComponentProps, LocalizedTextProps, LocalizedText } from "bernie-l10n";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { isVariantEnabled } from "components/utility/experiment";
import { useLocalization } from "@shared-ui/localization-context";

interface SeeAllContentLinkProps extends LocalizedComponentProps {
  model: HotelsFlexModuleResult;
  context: ExtendedContextStore;
  affinityDescription?: string;
}

export enum SHOW_MORE_STRATEGY {
  TOP_LINK = "top_link",
  BOTTOM_LINK = "bottom_link",
  BUTTON = "button",
}

enum GEO_TYPE {
  COUNTRY = "country",
  STATE = "province_state",
}

/**
 * Link that will bring the user to a list of all hotels at once instead of top 10
 * @param props
 */
export const SeeAllContentLink = (props: SeeAllContentLinkProps) => {
  const { formatText } = useLocalization();
  const { model, context, affinityDescription } = props;
  const { seeAllLink, showMoreStrategy, locationName, locationType } = model;

  const shouldHideLink =
    isVariantEnabled(context, "Blossom_SRP_Lite_V2") ||
    (showMoreStrategy !== SHOW_MORE_STRATEGY.TOP_LINK &&
      showMoreStrategy !== SHOW_MORE_STRATEGY.BUTTON &&
      showMoreStrategy !== SHOW_MORE_STRATEGY.BOTTOM_LINK);

  if (!seeAllLink || shouldHideLink) {
    return null;
  }

  const isBottomLink = showMoreStrategy === SHOW_MORE_STRATEGY.BOTTOM_LINK;
  const HLRGeoTypes = [GEO_TYPE.COUNTRY, GEO_TYPE.STATE] as string[];
  let localizedText: LocalizedTextProps = {
    message: "",
    data: [],
  };
  let formattedMessage;

  if (isBottomLink) {
    if (HLRGeoTypes.includes(locationType)) {
      localizedText.message = "hotels.seeMore";
    } else {
      localizedText.message = "hotels.seeMoreProperties";
    }
    formattedMessage = formatText(localizedText.message, localizedText.data).toUpperCase();
  } else {
    if (affinityDescription) {
      localizedText.message = "hotels.affinity.seeMoreProperties";
      localizedText.data = [affinityDescription, locationName];
    } else {
      localizedText.message = "hotels.seeAllAvailableProperties";
      localizedText.data = [locationName];
    }
  }

  return (
    <div className="see-all-hotels" data-testid="see-all-content-link">
      <UitkLink>
        <TrackedLink
          moduleName={model.name}
          href={seeAllLink}
          rfrr={`${model.propertyType}.${model.seeAllType}`}
          linkName={seeAllLink}
          rel="nofollow"
        >
          {isBottomLink && formattedMessage ? (
            <strong>{formattedMessage}</strong>
          ) : (
            <LocalizedText message={localizedText.message} data={localizedText.data} />
          )}
        </TrackedLink>
      </UitkLink>
    </div>
  );
};
