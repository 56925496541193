import { Hotel } from "typings/microserviceModels/hotels-flex-module";
import * as React from "react";
import { observer, inject } from "mobx-react";
import { useLocalization } from "@shared-ui/localization-context";

import { UitkRating, UitkRatingIcon } from "@egds/react-core/rating";
import { UitkHeading, UitkHeadingProps, UitkText } from "@egds/react-core/text";

import { LocalizedComponentClass, withLocalization, LocalizedComponentProps } from "bernie-l10n";

import { getHotelLinkRel } from "components/flexComponents/Hotels/components/HotelCard";
import { HotelAddressDistanceToPoiAndValueProposition } from "components/flexComponents/Hotels/components/HotelAddressDistanceToPoiAndValueProposition";
import { HotelAddressDistanceToPoiAndValuePropositionCOMET } from "components/flexComponents/Hotels/components/HotelAddressDistanceToPoiAndValuePropositionCOMET";
import { getAddress } from "components/flexComponents/Hotels/utils/getAddress";
import { useInteractiveFilters } from "src/components/flexComponents/PropertyFiltersInteractive/PropertyFiltersInteractiveContext";

import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { ratingIcon } from "components/utility/RatingUtil";
import { TrackedLink } from "components/utility/analytics/TrackedLink";

import { PropertyFiltersStore } from "src/stores/PropertyFiltersStore";

import { UitkLayoutPosition } from "@egds/react-core/layout-position";
import { getFormattedDistance } from "../utils/getFormattedDistance";

/**
 * CardContent which contains the hotel name, address and overall star rating
 */

export interface HotelNameRatingAndAddressProps extends LocalizedComponentProps {
  hotel: Hotel;
  locale: string;
  moduleName?: string;
  isViewCarousel?: boolean;
  context?: ExtendedContextStore;
  isTravelersLoved?: boolean;
  locationName: string;
  shouldUseImperialDistanceUnits?: boolean;
  addressDistanceView?: string;
  showDistance?: boolean;
  showAddress?: boolean;
  propertyFilters?: PropertyFiltersStore;
}

const HotelNameRatingAndAddressComponent = (props: HotelNameRatingAndAddressProps) => {
  const {
    hotel,
    locale,
    moduleName,
    isViewCarousel,
    context,
    isTravelersLoved,
    locationName,
    shouldUseImperialDistanceUnits,
    addressDistanceView,
    showDistance,
    showAddress,
    l10n,
    propertyFilters,
  } = props;

  const isImmediateFilteringEnabled = useInteractiveFilters();

  if (!hotel) {
    return null;
  }

  const { hotelStarRating, callToActionHotelNameUrl, distancePoi, hotelInfositeUrl, hotelName, hotelId } = hotel;

  const linkURL =
    isImmediateFilteringEnabled && propertyFilters?.hasInteractedWithFilters
      ? hotelInfositeUrl
      : callToActionHotelNameUrl;

  const hotelRatingIcon = ratingIcon(locale, context?.site?.id);

  const hotelLink = callToActionHotelNameUrl ? (
    <UitkLayoutPosition type="relative" tag="div">
      <div aria-label={hotelName} role="heading" aria-level={3}>
        <TrackedLink
          data-testid={`hotelname-link-${hotelId}`}
          moduleName={moduleName || "hotels"}
          className="hotelNameLink"
          rel={getHotelLinkRel(callToActionHotelNameUrl)}
          rfrr={`hotelName-${hotelId}`}
          href={linkURL}
          target="_blank"
          aria-hidden="true"
          tabIndex={-1}
        >
          <HotelName hotelName={hotelName} overflow="truncate" />
        </TrackedLink>
      </div>
    </UitkLayoutPosition>
  ) : (
    <HotelName hotelName={hotelName} overflow="truncate" />
  );

  const getDistanceToPOI = () => {
    if (!locationName || !distancePoi) {
      return;
    }

    // The choice of unit should really be done in the back-end or at least the back-end should provide info
    // about which unit to choose...
    const distanceUtil = shouldUseImperialDistanceUnits
      ? {
          key: "hotels.distanceToPoi.miles",
          value: distancePoi.miles,
        }
      : {
          key: "hotels.distanceToPoi.kilometers",
          value: distancePoi.kilometers,
        };

    const formattedDistance = getFormattedDistance(distanceUtil.value, locale);

    return l10n.formatText(distanceUtil.key, distanceUtil.value, formattedDistance, locationName);
  };

  return (
    <>
      {!isViewCarousel && (
        <UitkText size={600} weight="bold" overflow="truncate">
          {hotelLink}
        </UitkText>
      )}
      {isTravelersLoved && (
        <UitkText size={600} weight="bold">
          {hotelLink}
        </UitkText>
      )}

      <HotelRating hotelRatingIcon={hotelRatingIcon} hotelStarRating={hotelStarRating} brand={context?.site?.brand} />

      {addressDistanceView === "COMET" ? (
        <HotelAddressDistanceToPoiAndValuePropositionCOMET
          hotel={hotel}
          address={getAddress(hotel)}
          distance={getDistanceToPOI()}
          showDistance={showDistance && !!distancePoi}
          showAddress={showAddress}
          isViewCarousel={isViewCarousel}
        />
      ) : (
        <HotelAddressDistanceToPoiAndValueProposition
          hotel={hotel}
          address={getAddress(hotel)}
          distance={getDistanceToPOI()}
          showDistance={!!distancePoi}
          isViewCarousel={isViewCarousel}
        />
      )}
    </>
  );
};

export const HotelNameRatingAndAddress: LocalizedComponentClass<HotelNameRatingAndAddressProps> = inject(
  "propertyFilters"
)(observer(withLocalization(HotelNameRatingAndAddressComponent)));

interface HotelRatingProps {
  brand?: string;
  hotelRatingIcon?: UitkRatingIcon;
  hotelStarRating: string;
  isText?: boolean;
}

export const HotelRating = React.memo(({ brand, hotelRatingIcon, hotelStarRating, isText }: HotelRatingProps) => {
  const { formatText } = useLocalization();

  if (isText) {
    const ratingText = formatText(
      "hotels.affinity.luxuryAmenities.starProperty",
      Number(hotelStarRating),
      hotelStarRating
    );

    return (
      <UitkText size={300} weight="bold">
        {ratingText}
      </UitkText>
    );
  }

  return <UitkRating className={brand} icon={hotelRatingIcon} rating={hotelStarRating} />;
});

interface HotelNameProps extends UitkHeadingProps {
  hotelName: string;
}

export const HotelName = ({ hotelName, overflow, tag }: HotelNameProps) => (
  <UitkHeading size={5} data-testid="hotel-name" overflow={overflow} tag={tag}>
    {hotelName}
  </UitkHeading>
);
