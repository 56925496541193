// External Libraries
import * as React from "react";
import { UitkSpacing } from "@egds/react-core/spacing";

// Components
import { ModuleTitle } from "components/flexComponents/Hotels/components/ModuleTitle";
import { LegalText } from "components/flexComponents/Hotels/components/LegalText";
import { HotelStarRatingAffinityFilters } from "components/flexComponents/Hotels/components/HotelAffinityFilters/HotelStarRatingAffinityFilters";

// Utils
import { useLocalization } from "@shared-ui/localization-context";
import { useHotelsContext } from "components/flexComponents/Hotels/components/HotelsContext";
import { getStarRating } from "components/utility/ContextUtil";

interface SummarizedHotelsCommonViewProps {
  hideTitle?: boolean;
}
export const SummarizedHotelsCommonView: React.FC<SummarizedHotelsCommonViewProps> = (prop) => {
  const { hideTitle, children } = prop;
  const { formatText } = useLocalization();
  const { model, context } = useHotelsContext();
  const hotelsStarRating = model.starRating;

  const regionName: string = model.locationName;

  const pageStarRating = getStarRating(context);

  const headingKey = "hotels.affinity.starRating.similar.title";
  const subHeadingKey = "hotels.affinity.starRating.similar.subtitle";

  const heading = hotelsStarRating ? formatText(headingKey, hotelsStarRating) : model.title;
  const subHeading = hotelsStarRating
    ? formatText(subHeadingKey, hotelsStarRating, pageStarRating, regionName)
    : model.subTitle;

  return (
    <>
      {hideTitle ? (
        <ModuleTitle heading={""} subHeading={subHeading} />
      ) : (
        <ModuleTitle heading={heading} subHeading={subHeading} />
      )}
      {children}
      <HotelStarRatingAffinityFilters />
      <UitkSpacing padding={{ blockstart: "two" }}>
        <LegalText />
      </UitkSpacing>
    </>
  );
};
