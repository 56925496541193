import * as React from "react";
import { LocalizedComponentClass, withLocalization } from "bernie-l10n";
import { UitkIcon, UitkIconSize } from "@egds/react-core/icons";
import { UitkText, UitkParagraph } from "@egds/react-core/text";
import { UitkSpacing } from "@egds/react-core/spacing";
import { TravelerReviewContent } from "typings/microserviceModels/hotels-flex-module";
import { useLocalization } from "@shared-ui/localization-context";

interface TravelerReviewsContentProps {
  hotelId: string;
  travelerReviewContent?: TravelerReviewContent;
}

export const TravelerReviewsComponent = (props: TravelerReviewsContentProps) => {
  if (!props.travelerReviewContent) {
    return null;
  }
  const { formatText } = useLocalization();
  const { titleKey, contentText } = props.travelerReviewContent;
  const title = titleKey && formatText(titleKey);
  const paragraphText = `${title} ${contentText}`;

  return (
    <UitkParagraph>
      <UitkSpacing padding={{ inlineend: "one" }}>
        <UitkIcon name="sentiment_4" size={UitkIconSize.SMALL} />
      </UitkSpacing>
      <UitkText size={200} inline>
        {paragraphText}
      </UitkText>
    </UitkParagraph>
  );
};

export const TravelerReviewsContent: LocalizedComponentClass<TravelerReviewsContentProps> = withLocalization(
  TravelerReviewsComponent
);
