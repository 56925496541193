import * as React from "react";

import { UitkCard, UitkCardContentSection, UitkCardLink } from "@egds/react-core/cards";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { UitkFigure, UitkFigureAspectRatioType, UitkImage } from "@egds/react-core/images";
import { UitkHeading, UitkText } from "@egds/react-core/text";
import { UitkRating } from "@egds/react-core/rating";
import { UitkSpacing } from "@egds/react-core/spacing";

import PriceLockup from "components/flexComponents/Hotels/views/HotelsViews/components/shared/PriceLockup";
import DistanceOrRating from "components/flexComponents/Hotels/views/HotelsViews/components/shared/DistanceOrRating";
import DescriptionOrReview from "components/flexComponents/Hotels/views/HotelsViews/components/shared/DescriptionOrReview";
import AmenitiesOrRecommendation from "components/flexComponents/Hotels/views/HotelsViews/components/shared/AmenitiesOrRecommendation";
import ValueProposition from "components/flexComponents/Hotels/views/HotelsViews/components/shared/ValueProposition";
import { getHotelLink, getHotelLinkRel } from "components/flexComponents/Hotels/components/HotelCard";

import { getOptimizedImageUrl } from "src/components/flexComponents/Hotels/utils/hotelOptimizedImage";
import { ratingIcon } from "components/utility/RatingUtil";
import { TrackedLink } from "components/utility/analytics/TrackedLink";

import { HotelPriceLockup } from "src/components/flexComponents/Hotels/utils/A11y/HotelPriceLockup";
import { useLocalization } from "@shared-ui/localization-context";
import { fireAffinityProductCardSelectedClickstreamEvent } from "src/components/flexComponents/Hotels/utils/fireAffinityProductCardSelectedClickstreamEvent";
import { HotelImageCardProps } from "src/components/flexComponents/Hotels/typings";

const HotelCard = (props: HotelImageCardProps) => {
  const { context, model, hotel, index } = props;
  const { callToAction, destinationId } = model;
  const { hotelName, hotelStarRating, hotelFormattedFullAddress } = hotel;

  const hotelImageUrl = getOptimizedImageUrl(hotel, context);

  const { formatText } = useLocalization();

  return (
    <UitkCard padded onClick={fireAffinityProductCardSelectedClickstreamEvent(hotel, index)}>
      <UitkLayoutFlex space="three">
        <UitkLayoutFlexItem minWidth={100} shrink={0}>
          <div>
            <UitkFigure ratio={UitkFigureAspectRatioType.R1_1} className="uitk-card-roundcorner-all">
              <UitkImage src={hotelImageUrl} alt={hotel.hotelName} placeholderImage lazyLoading="lazy" />
            </UitkFigure>
          </div>
        </UitkLayoutFlexItem>
        <UitkLayoutFlexItem grow={1}>
          <div>
            <UitkCardContentSection padded={false}>
              <UitkLayoutFlex direction="column">
                <UitkSpacing margin={{ blockend: "three" }}>
                  <UitkLayoutFlex justifyContent="space-between">
                    <UitkLayoutFlex direction="column">
                      <UitkHeading tag="h3" size={5}>
                        {hotelName}
                      </UitkHeading>
                      <UitkRating
                        rating={hotelStarRating}
                        icon={ratingIcon(context.locale, context.site.id)}
                        className={context.site.brand}
                      />
                      <UitkText size={300}>{hotelFormattedFullAddress}</UitkText>
                    </UitkLayoutFlex>
                    <UitkLayoutFlex>
                      <PriceLockup
                        hotel={hotel}
                        hotelLink={getHotelLink(hotel, model.destinationId, context)}
                        priceA11y={new HotelPriceLockup(hotel, formatText)}
                        hasGetRatesText
                      />
                    </UitkLayoutFlex>
                  </UitkLayoutFlex>
                </UitkSpacing>
                <UitkSpacing margin={{ blockend: "three" }}>
                  <ValueProposition hotel={hotel} />
                </UitkSpacing>
                <UitkSpacing margin={{ blockend: "three" }}>
                  <div>
                    <DistanceOrRating model={model} hotel={hotel} context={context} />
                  </div>
                </UitkSpacing>
              </UitkLayoutFlex>
            </UitkCardContentSection>
            <UitkCardContentSection padded={false} border="top">
              <UitkSpacing margin={{ blockstart: "three" }}>
                <DescriptionOrReview model={model} hotel={hotel} />
              </UitkSpacing>
              <UitkSpacing margin={{ blockstart: "three" }}>
                <AmenitiesOrRecommendation model={model} hotel={hotel} />
              </UitkSpacing>
            </UitkCardContentSection>
          </div>
        </UitkLayoutFlexItem>
      </UitkLayoutFlex>

      <UitkCardLink>
        <TrackedLink
          data-testid={`hotelcard-link-${hotelName}`}
          aria-label={hotelName}
          moduleName="hotels"
          rfrr="card"
          rel={getHotelLinkRel(callToAction)}
          href={getHotelLink(hotel, destinationId || context.searchContext.location?.id, context, callToAction)}
        />
      </UitkCardLink>
    </UitkCard>
  );
};

export default HotelCard;
