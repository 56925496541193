import * as React from "react";
import { AnalyticsStore } from "bernie-plugin-mobx";
import { useLocalization } from "@shared-ui/localization-context";

import { UitkCard, UitkCardContentSection, UitkCardLink } from "@egds/react-core/cards";
import { UitkFigure, UitkFigureAspectRatioType, UitkImage } from "@egds/react-core/images";
import { UitkHeading, UitkText } from "@egds/react-core/text";
import { UitkRating } from "@egds/react-core/rating";
import { UitkLayoutFlex } from "@egds/react-core/layout-flex";
import { UitkLayoutGrid, UitkLayoutGridItem } from "@egds/react-core/layout-grid";
import { UitkExpandoLink } from "@egds/react-core/expando";
import { UitkPrimaryButton } from "@egds/react-core/button";
import { AnalyticsConfig } from "@egds/react-core/utils";
import { UitkSpacing } from "@egds/react-core/spacing";

import PriceLockup from "components/flexComponents/Hotels/views/HotelsViews/components/shared/PriceLockup";
import ValueProposition from "components/flexComponents/Hotels/views/HotelsViews/components/shared/ValueProposition";
import DistanceOrRating from "components/flexComponents/Hotels/views/HotelsViews/components/shared/DistanceOrRating";
import DescriptionOrReview from "components/flexComponents/Hotels/views/HotelsViews/components/shared/DescriptionOrReview";
import AmenitiesOrRecommendation from "components/flexComponents/Hotels/views/HotelsViews/components/shared/AmenitiesOrRecommendation";
import { getOptimizedImageUrl } from "src/components/flexComponents/Hotels/utils/hotelOptimizedImage";
import {
  expandoCollapsedTitle,
  expandoExpandedTitle,
} from "components/flexComponents/Hotels/views/HotelsViews/HotelUtils";
import { getHotelLink, getHotelLinkRel } from "components/flexComponents/Hotels/components/HotelCard";

import { ratingIcon } from "components/utility/RatingUtil";
import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { FlexClickTracker } from "components/utility/analytics/FlexClickTracker/FlexClickTracker";
import { Action, FlexTrackingInfo, sendDelayedTrackEvent } from "components/utility/analytics/FlexAnalyticsUtils";

import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { Hotel, HotelsFlexModuleResult } from "typings/microserviceModels/hotels-flex-module";
import { withStores } from "stores";
import { HotelPriceLockup } from "src/components/flexComponents/Hotels/utils/A11y/HotelPriceLockup";
import { fireAffinityProductCardSelectedClickstreamEvent } from "src/components/flexComponents/Hotels/utils/fireAffinityProductCardSelectedClickstreamEvent";

interface HotelImageCardProps {
  context: ExtendedContextStore;
  model: HotelsFlexModuleResult;
  hotel: Hotel;
  analytics: AnalyticsStore;
  index: number;
}

const HotelCard = withStores("analytics")((props: HotelImageCardProps) => {
  const { hotel, model, context, index } = props;
  const { callToAction, contentRecipe, destinationId } = model;
  const { hotelId, hotelName, hotelStarRating, hotelFormattedFullAddress } = hotel;

  const hotelImageUrl = getOptimizedImageUrl(hotel, context);

  const { formatText } = useLocalization();

  const [isExpandoVisible, setIsExpandoVisible] = React.useState(false);

  /* covered in e2e test */
  /* istanbul ignore next */
  const expandoLinkAnalytics: AnalyticsConfig = {
    id: "LP.Hotels.HotelImageLeftSmall.ExpandoLink",
    callback: (theId, description) => {
      const flexTrackingInfo: FlexTrackingInfo = {
        moduleName: theId,
        action: Action.CLICK,
        linkName: description,
      };
      sendDelayedTrackEvent(flexTrackingInfo, props.analytics);
    },
  };

  const ButtonTracked = FlexClickTracker(UitkPrimaryButton);

  return (
    <UitkCard>
      <div onClick={fireAffinityProductCardSelectedClickstreamEvent(hotel, index)}>
        <UitkCardContentSection padded={false}>
          <UitkCardContentSection>
            <UitkLayoutGrid columns={3} space="three">
              <UitkLayoutGridItem colSpan={1}>
                <UitkFigure ratio={UitkFigureAspectRatioType.R1_1} className="uitk-card-roundcorner-all">
                  <UitkImage src={hotelImageUrl} alt={hotel.hotelName} placeholderImage lazyLoading="lazy" />
                </UitkFigure>
              </UitkLayoutGridItem>

              <UitkLayoutGridItem colSpan={2}>
                <UitkLayoutFlex direction="column">
                  <UitkHeading tag="h3" size={5} overflow="truncate-2-lines">
                    {hotelName}
                  </UitkHeading>
                  <UitkRating
                    rating={hotelStarRating}
                    icon={ratingIcon(context.locale, context.site.id)}
                    className={context.site.brand}
                  />
                  <UitkText size={300}>{hotelFormattedFullAddress}</UitkText>
                </UitkLayoutFlex>
              </UitkLayoutGridItem>
            </UitkLayoutGrid>
          </UitkCardContentSection>

          <UitkCardContentSection border="top">
            <UitkLayoutFlex justifyContent="space-between">
              <UitkLayoutFlex direction="column">
                <UitkSpacing margin={{ blockend: "four" }}>
                  <ValueProposition hotel={hotel} />
                </UitkSpacing>
                <DistanceOrRating model={model} hotel={hotel} context={context} />
              </UitkLayoutFlex>
              <UitkLayoutFlex>
                <PriceLockup
                  hotel={hotel}
                  hotelLink={getHotelLink(hotel, model.destinationId, context)}
                  priceA11y={new HotelPriceLockup(hotel, formatText)}
                  hasGetRatesText
                  textAlignment="right"
                />
              </UitkLayoutFlex>
            </UitkLayoutFlex>
          </UitkCardContentSection>

          <UitkCardLink>
            <TrackedLink
              data-testid={`hotelcard-link-${hotel.hotelName}`}
              aria-label={hotel.hotelName}
              moduleName="hotels"
              rfrr="card"
              rel={getHotelLinkRel(callToAction)}
              href={getHotelLink(hotel, destinationId || context.searchContext.location?.id, context, callToAction)}
            />
          </UitkCardLink>
        </UitkCardContentSection>
      </div>

      <UitkCardContentSection border="top">
        <UitkExpandoLink
          isVisible={isExpandoVisible}
          expandoTitle={expandoCollapsedTitle(contentRecipe)}
          expandedLabel={expandoExpandedTitle(contentRecipe)}
          analytics={expandoLinkAnalytics}
          onToggle={() => setIsExpandoVisible((prevIsVisible) => !prevIsVisible)}
          data-testid={`expando-hotelcard-${hotelId}`}
        >
          <UitkSpacing margin={{ blockstart: "three" }}>
            <DescriptionOrReview model={model} hotel={hotel} />
          </UitkSpacing>
          <UitkSpacing margin={{ blockstart: "three" }}>
            <AmenitiesOrRecommendation model={model} hotel={hotel} />
          </UitkSpacing>
          <UitkSpacing margin={{ blockstart: "three" }}>
            <ButtonTracked
              href={getHotelLink(hotel, destinationId || context.searchContext.location?.id, context, callToAction)}
              isFullWidth
              moduleName="LP.hotels.hotelImageLeftSmall"
              rfrr="expandoExpanded.select"
              onClick={fireAffinityProductCardSelectedClickstreamEvent(hotel, index)}
            >
              {formatText("hotelsViews.expando.select")}
            </ButtonTracked>
          </UitkSpacing>
        </UitkExpandoLink>
      </UitkCardContentSection>
    </UitkCard>
  );
});

export default HotelCard;
