import { TravelerReviewContent } from "typings/microserviceModels/hotels-flex-module";
import * as React from "react";
import { UitkBadge } from "@egds/react-core/badge";
import { UitkIcon } from "@egds/react-core/icons";
import { LocalizedComponentClass, withLocalization } from "bernie-l10n";
import { useLocalization } from "@shared-ui/localization-context";
import { UitkSpacing } from "@egds/react-core/spacing";

interface GreatForAffinityBadgeProps {
  travelerReviewContent: TravelerReviewContent;
}

export const GreatForAffinityBadgeComponent = (props: GreatForAffinityBadgeProps) => {
  const { hotelsBadge } = props.travelerReviewContent;

  if (!hotelsBadge) {
    return null;
  }
  const { formatText } = useLocalization();

  const badgeText = formatText(hotelsBadge.text);

  return (
    <UitkSpacing margin={{ blockstart: "three", inlinestart: "three" }}>
      <UitkBadge className="hotelBadge" text={badgeText} theme={hotelsBadge.theme}>
        {hotelsBadge.icon && <UitkIcon name={hotelsBadge.icon} />}
      </UitkBadge>
    </UitkSpacing>
  );
};

export const GreatForAffinityBadge: LocalizedComponentClass<GreatForAffinityBadgeProps> = withLocalization(
  GreatForAffinityBadgeComponent
);
